type ErrorMessageProps = {
    message?: string;
};

function ErrorMessage({ message }: ErrorMessageProps) {
    return (
        <p
            style={{
                paddingTop: '5px',
                paddingBottom: '5px',
                color: 'red',
                fontSize: '17px',
                textAlign: 'left',
            }}
        >
            {message ? message : '필수입니다.'}
        </p>
    );
}

export default ErrorMessage;
